/* I'll do something with this component if I have the time, but adding place will probably be a bit too labor intensive while I'm on a trip anyway */
import React from 'react';

class TravelDateVideoPlaces extends React.Component {

  render() {
    return (
      <></>
    );
  }

}

export default TravelDateVideoPlaces;
